import clsx from 'clsx';

import './Badge.scss';

type BadgeProps = {
  value: number;
  max?: number;
  className?: string;
};

export function Badge(props: BadgeProps) {
  const valueDisplay = props.max !== undefined && props.value > props.max ? `${props.max}+` : props.value;

  return <p className={clsx('Badge', props.className)}>{valueDisplay}</p>;
}
