import { useEffect, useState } from 'react';
import { StreamChat, TokenOrProvider, User } from 'stream-chat';

type UseStreamChatClientOptions = {
  apiKey: string;
  user: User;
  tokenOrProvider?: TokenOrProvider;
};

type UseStreamChatClient = {
  client: StreamChat | undefined;
  isLoading: boolean;
  error: Error | null;
};

export function useStreamChatClient(options: UseStreamChatClientOptions) {
  const [chatClient, setChatClient] = useState<UseStreamChatClient>({ client: undefined, isLoading: true, error: null });

  useEffect(() => {
    const client = new StreamChat(options.apiKey);

    let didUserConnectInterrupt = false;
    let connectionPromise: Promise<void> | undefined;

    if (options.tokenOrProvider) {
      connectionPromise = client
        .connectUser(options.user, options.tokenOrProvider)
        .then(() => {
          if (!didUserConnectInterrupt) {
            setChatClient({ client, isLoading: false, error: null });
          }
        })
        .catch((error: Error) => {
          setChatClient({
            client: undefined,
            isLoading: false,
            error,
          });
        });
    }

    return () => {
      didUserConnectInterrupt = true;
      setChatClient({ client: undefined, isLoading: false, error: null });
      connectionPromise?.then(() => client.disconnectUser());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should re-run only if user.id changes
  }, [options.apiKey, options.tokenOrProvider, options.user.id]);

  return chatClient;
}
