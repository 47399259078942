import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/mainComponents/Button';
import { Dropdown, DropdownItem, DropdownLabel } from 'src/components/mainComponents/Dropdown';
import { SvgIcon } from 'src/components/mainComponents/SvgIcon';

export type EmailReminderButtonProps = {
  isEnabled: boolean;
  onChanged: (isEnabled: boolean) => Promise<unknown> | unknown;
};

export function EmailReminderButton(props: EmailReminderButtonProps) {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  return (
    <Dropdown
      side='top'
      align='start'
      trigger={
        <Button
          disabled={isLoading}
          icon={isLoading ? 'loading-spinner--light--one-quarter' : 'bell'}
          title={t('LiveChat.email_notification_title') ?? undefined}
          size='xl'
          variant='text'
        />
      }
    >
      <DropdownLabel>{t('LiveChat.email_notification_title')}</DropdownLabel>
      <DropdownItem onSelect={() => changeSetting(true)}>
        {props.isEnabled && <SvgIcon name='checkmark' />}
        {t('LiveChat.email_notification_enabled')}
      </DropdownItem>
      <DropdownItem onSelect={() => changeSetting(false)}>
        {!props.isEnabled && <SvgIcon name='checkmark' />}
        {t('LiveChat.email_notification_disabled')}
      </DropdownItem>
    </Dropdown>
  );

  async function changeSetting(isEnabled: boolean) {
    setLoading(true);
    await props.onChanged(isEnabled);

    setLoading(false);
  }
}
