import clsx from 'clsx';
import type { User } from 'stream-chat';
import { Channel, ChannelHeader, MessageList, Thread, Window } from 'stream-chat-react';

import { Button } from 'src/components/mainComponents/Button';
import useDisclosure from 'src/hooks/useDisclosure';

import { ChannelAvatar } from '../ChannelAvatar';
import { ChannelsList } from '../ChannelsList/ChannelsList';
import { CustomThreadHeader } from '../CustomThreadHeader';
import { type EmailReminderButtonProps } from '../EmailReminderButton';
import type { LiveChatChannelTrainingSession, LiveChatUserSetting } from '../liveChat.types';
import { MessageInput } from '../MessageInput/MessageInput';
import '../streamCssOverride.scss';
import './LiveChatDisplay.scss';

export type LiveChatDisplayProps = {
  user: User;
  userSetting?: LiveChatUserSetting;
  onEmailReminderSettingChanged: EmailReminderButtonProps['onChanged'];
  channelTrainingSessions: LiveChatChannelTrainingSession[];
};

export function LiveChatDisplay(props: LiveChatDisplayProps) {
  const { isOpen: isChannelsListOpen, open: openChannelsList, close: closeChannelsList } = useDisclosure(false);

  return (
    <div className='LiveChatDisplay'>
      <div
        className={clsx('LiveChatDisplay__channels-list-pane', { 'LiveChatDisplay__channels-list-pane--open': isChannelsListOpen })}
      >
        <ChannelsList userId={props.user.id} onClose={closeChannelsList} {...props} />
      </div>
      <div className='LiveChatDisplay__channel-pane'>
        <Channel ThreadHeader={CustomThreadHeader}>
          <Window>
            <div className='LiveChatDisplay__channel-header'>
              <Button
                variant='text'
                size='xl'
                icon='burger--menu'
                onClick={openChannelsList}
                className='LiveChatDisplay__show-channels-list-button'
              />
              <ChannelHeader Avatar={ChannelAvatar} />
            </div>
            <MessageList />
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      </div>
    </div>
  );
}
