import { Content, Overlay, Portal, Root } from '@radix-ui/react-dialog';
import PropTypes from 'prop-types';

import './Drawer.scss';

export function Drawer({ children, isOpen, onClose }) {
  return (
    <Root open={isOpen} onOpenChange={onClose}>
      <Portal>
        <Overlay className='Drawer__overlay' />
        <Content className='Drawer__content'>{children}</Content>
      </Portal>
    </Root>
  );
}

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
