import { Channel } from 'stream-chat';

import { GuestType } from 'src/user';

import type { LiveChatChannelTrainingSession } from './liveChat.types';

const PRIVATE_CHANNEL_ID_REGEX = /[0-9]*-[0-9]*-[0-9]*/;

export type ChannelType = 'public' | 'private';
type PrivateChannelRecipientType = GuestType;

/**
 * Get the type of a channel.
 * @throws If the channel has no ID.
 * @param channel Channel to get the type of.
 * @returns The channel type.
 */
export function getChannelType(channel: Channel): ChannelType {
  if (!channel.id) {
    throw new Error('channel has no id');
  }

  // Private channels have the following ID format:
  // `<academy hash>-<instructor ID>-<trainee ID>`
  if (PRIVATE_CHANNEL_ID_REGEX.test(channel.id)) {
    return 'private';
  }

  return 'public';
}

/**
 * Get the type of the recipient in a private channel.
 *
 * @throws If the channel has no ID or if the channel is not private.
 * @param channel Channel to get the recipient type of.
 * @param currentUserId ID of the current user.
 * @returns The recipient type of the private channel.
 */
export function getPrivateChannelRecipientType(channel: Channel, currentUserId: string): PrivateChannelRecipientType {
  if (!channel.id) {
    throw new Error('channel has no id');
  }

  if (getChannelType(channel) !== 'private') {
    throw new Error('channel is not private');
  }

  const recipientId = Object.keys(channel.state.members).find(memberId => memberId !== currentUserId);

  if (!recipientId) {
    throw new Error('channel has no recipient');
  }

  return channel.state.members[recipientId].user?.role as GuestType;
}

/**
 * Group {@link LiveChatChannelTrainingSession}s by training session.
 * @param channelTrainingSessions {@link LiveChatChannelTrainingSession}s to group by training session.
 * @returns A map keyed by training session ID, with the list of channels in that training session.
 */
export function groupChannelsPerTrainingSession(channelTrainingSessions: LiveChatChannelTrainingSession[]) {
  return channelTrainingSessions.reduce((trainingSessions, channelTrainingSession) => {
    if (trainingSessions.has(channelTrainingSession.trainingSessionId)) {
      trainingSessions.get(channelTrainingSession.trainingSessionId)!.channels.push(channelTrainingSession);
    } else {
      trainingSessions.set(channelTrainingSession.trainingSessionId, {
        id: channelTrainingSession.trainingSessionId,
        name: channelTrainingSession.trainingSessionName,
        channels: [channelTrainingSession],
      });
    }

    return trainingSessions;
  }, new Map<string, { id: string; name: string; channels: LiveChatChannelTrainingSession[] }>());
}
