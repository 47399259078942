import type { StreamChat } from 'stream-chat';
import { Chat } from 'stream-chat-react';
import 'stream-chat-react/dist/scss/v2/index.scss';

import { LiveChatDisplay, LiveChatDisplayProps } from '../LiveChatDisplay';
import './LiveChat.scss';

type LiveChatProps = {
  client?: StreamChat;
  userSetting: LiveChatDisplayProps['userSetting'];
  onEmailReminderSettingChanged: LiveChatDisplayProps['onEmailReminderSettingChanged'];
  channelTrainingSessions: LiveChatDisplayProps['channelTrainingSessions'];
};

export function LiveChat(props: LiveChatProps) {
  if (!props.client) {
    return null;
  }

  return (
    <div className='LiveChat'>
      <Chat client={props.client} theme='str-chat__theme-light'>
        <LiveChatDisplay
          user={props.client.user!}
          userSetting={props.userSetting}
          onEmailReminderSettingChanged={props.onEmailReminderSettingChanged}
          channelTrainingSessions={props.channelTrainingSessions}
        />
      </Chat>
    </div>
  );
}
