import '../../../scss/mainComponents/ImageChip.scss';

export const ImageChip = ({ url }) => {
  return (
    <div className='image-chip'>
      <img src={url} alt='logo-company' />
    </div>
  );
};

export default ImageChip;
