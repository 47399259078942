import { Avatar as StreamAvatar } from 'stream-chat-react';

import { SvgIcon } from 'src/components/mainComponents/SvgIcon';

import { ChannelType } from '../liveChat.business';
import './ChannelAvatar.scss';

type ChannelAvatarBaseProps = {
  channelType: ChannelType;
  name?: string;
  image?: string | null;
};

/**
 * Renders an Avatar for a live chat channel.
 */
export function ChannelAvatarBase(props: ChannelAvatarBaseProps) {
  const classname = `ChannelAvatar ChannelAvatar--${props.channelType}`;

  if (props.channelType === 'public') {
    return (
      <div className={classname}>
        <SvgIcon name='users' size='lg' />
      </div>
    );
  }

  return <StreamAvatar image={props.image} name={props.name} size={40} />;
}
