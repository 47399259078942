import clsx from 'clsx';
import { ReactNode } from 'react';

import { Color } from 'src/components/v2/ColorConfiguration';

import './Pill.scss';

type PillProps = {
  color?: Color;
  children: ReactNode;
  className?: string;
};

export function Pill(props: PillProps) {
  const color = props.color || 'primary';

  return <p className={clsx('Pill', `Pill--${color}`, props.className)}>{props.children}</p>;
}
