import { clsx } from 'clsx';
import PropTypes from 'prop-types';

import './HorizontalRule.scss';

export function HorizontalRule({ tight, className: additionalClassName }) {
  const className = clsx('HorizontalRule', { 'HorizontalRule--tight': tight }, additionalClassName);

  return <hr className={className} />;
}

HorizontalRule.propTypes = {
  className: PropTypes.string,
  tight: PropTypes.bool,
};
