import { lazy, Suspense, useEffect } from 'react';

import RootErrorBoundary from 'src/components/RootErrorBoundary';
import { RootErrorBoundary as RootErrorBoundaryV2 } from 'src/components/v2/RootErrorBoundary';
import { UIv2Guard, V2ActivationDialog } from 'src/components/v2Activation';
import { Providers } from 'src/Providers';
import { getGuestInfo, getUserCode } from 'src/user';

const RoutesV1 = lazy(() => import('src/components/routes/RoutesV1'));
const RoutesV2 = lazy(() => import('src/components/routes/RoutesV2'));

export function App({ sentryClient }) {
  useEffect(() => {
    if (getUserCode() === 'www' && window.location.pathname !== '/home') {
      window.location.pathname = '/home';
    }
  }, []);

  const guestInfo = getGuestInfo();

  if (guestInfo) {
    delete guestInfo.__typename;
    sentryClient.setUser({ ...guestInfo, userCode: getUserCode() });
  }

  return (
    <Providers>
      <UIv2Guard>
        {({ isV2Active, isV2Activable }) => {
          if (import.meta.env.DEV) {
            console.log(`LMS version : ${isV2Active ? 2 : 1}`);
          }
          if (isV2Active)
            return (
              <RootErrorBoundaryV2>
                <Suspense>
                  <RoutesV2 />
                </Suspense>
              </RootErrorBoundaryV2>
            );

          return (
            <RootErrorBoundary>
              <V2ActivationDialog isActivable={isV2Activable}>
                <Suspense>
                  <RoutesV1 />
                </Suspense>
              </V2ActivationDialog>
            </RootErrorBoundary>
          );
        }}
      </UIv2Guard>
    </Providers>
  );
}
