import { gql } from '@apollo/client';

export const EXTRANET_CHAT = gql`
  query liveChat {
    liveChat {
      token
      userSetting {
        emailReminderEnabled
      }
      channelTrainingSessions {
        channelId
        trainingSessionId
        trainingSessionName
      }
    }
  }
`;

export const UPDATE_USER_SETTING = gql`
  mutation updateLiveChatUserSetting($setting: LiveChatUserSettingInput!) {
    updateLiveChatUserSetting(setting: $setting) {
      emailReminderEnabled
    }
  }
`;
