import { type AvatarProps as StreamAvatarProps, useChannelStateContext } from 'stream-chat-react';

import { ChannelAvatarBase } from '.';
import { getChannelType } from '../liveChat.business';

/**
 * Renders an Avatar for a live chat channel, using the `ChannelStateContext`.
 */
export function ChannelAvatar(props: StreamAvatarProps) {
  const channelState = useChannelStateContext();
  const channelType = getChannelType(channelState?.channel);

  return <ChannelAvatarBase channelType={channelType} image={props.image} name={props.name} />;
}
