import { useEffect, useState } from 'react';
import { ChannelFilters, StreamChat } from 'stream-chat';

import { sortTrainingSessions } from 'src/controllers/moduleController';

import { groupChannelsPerTrainingSession } from '../liveChat.business';

type UseChannelFilterOptionsData = ReturnType<typeof groupChannelsPerTrainingSession>;
type ChannelFilterOption = ReturnType<typeof getOptionsFromData>[number];

/**
 * Get the list of options available for the channel filter.
 * @param trainingSessionToChannelsMap Map of training session IDs to the
 * list of channels they are related to.
 * @param channelQueryFilters Filters applied to the channels query.
 * @param chatClient Stream chat client.
 * @returns The list of options available for the channel filter.
 */
export function useChannelFilterOptions(
  trainingSessionToChannelsMap: UseChannelFilterOptionsData,
  channelQueryFilters: ChannelFilters,
  chatClient: StreamChat
) {
  const [options, setOptions] = useState<ChannelFilterOption[]>(getOptionsFromData(trainingSessionToChannelsMap));

  useEffect(() => {
    filterChannelFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return options;

  /**
   * Remove filter options that don't have any channel.
   */
  async function filterChannelFilterOptions() {
    const channels = await chatClient.queryChannels(channelQueryFilters);
    const channelIds = channels.reduce((ids, channel) => (channel.id ? ids.concat(channel.id) : ids), new Array<string>());

    setOptions(options => {
      return options.filter(option => isOptionIncluded(option, channelIds));
    });
  }
}

/**
 * Get a list of options from the data map.
 * @param trainingSessionToChannelsMap Map of training session IDs to the
 * list of channels they are related to.
 * @returns The list of options.
 */
function getOptionsFromData(trainingSessionToChannelsMap: UseChannelFilterOptionsData) {
  const trainingSessions = Array.from(trainingSessionToChannelsMap.values());

  return sortTrainingSessions(trainingSessions) as typeof trainingSessions;
}

/**
 * Get whether the option should be included based on the channel(s)
 * it relates to.
 * @param option Option to check if it should be included.
 * @param channelIds All the available channels IDs.
 * @returns Whether the option should be included.
 */
function isOptionIncluded(option: ReturnType<typeof getOptionsFromData>[number], channelIds: string[]) {
  const optionChannelIds = option.channels.map(channel => channel.channelId);
  return optionChannelIds.some(channelId => channelIds.includes(channelId));
}
