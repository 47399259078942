import {
  Content,
  Group,
  Icon,
  Item,
  ItemIndicator,
  ItemText,
  Label,
  Portal,
  type SelectGroupProps as RadixGroupProps,
  type SelectItemProps as RadixItemProps,
  type SelectItemTextProps as RadixItemTextProps,
  type SelectLabelProps as RadixLabelProps,
  type SelectProps as RadixRootProps,
  type SelectTriggerProps as RadixTriggerProps,
  type SelectValueProps as RadixValueProps,
  type SelectViewportProps as RadixViewportProps,
  Root,
  ScrollDownButton,
  ScrollUpButton,
  Separator,
  Trigger,
  Value,
  Viewport,
} from '@radix-ui/react-select';
import { clsx } from 'clsx';
import { forwardRef } from 'react';

import { SvgIcon, type SvgIconProps } from 'src/components/mainComponents/SvgIcon';

import './Select.scss';

type SelectProps = {
  children: RadixViewportProps['children'];
  placeholder?: RadixValueProps['placeholder'];
  id?: RadixTriggerProps['id'];
  value?: RadixRootProps['value'];
  onChange?: RadixRootProps['onValueChange'];
  defaultValue?: RadixRootProps['defaultValue'];
  label?: string;
};

export const Select = ({ placeholder, children, id, value, onChange, defaultValue, label }: SelectProps) => {
  return (
    <label className='SelectRoot__label'>
      {label && <span>{label}</span>}
      <Root onValueChange={onChange} value={value} defaultValue={defaultValue}>
        <Trigger id={id} className='SelectRoot__trigger'>
          <Value placeholder={placeholder} />
          <Icon className='SelectRoot__icon'>
            <SvgIcon name='chevron--down' size='md' />
          </Icon>
        </Trigger>
        <Portal>
          <Content className='SelectRoot__content'>
            <ScrollUpButton className='SelectRoot__scrollBtn'>
              <SvgIcon name='chevron--up' />
            </ScrollUpButton>
            <Viewport>{children}</Viewport>
            <ScrollDownButton className='SelectRoot__scrollBtn'>
              <SvgIcon name='chevron--down' />
            </ScrollDownButton>
          </Content>
        </Portal>
      </Root>
    </label>
  );
};

type SelectItemProps = RadixItemProps & {
  children: RadixItemTextProps['children'];
  rightIcon?: SvgIconProps['name'];
};

Select.Item = forwardRef<HTMLDivElement, SelectItemProps>(({ children, disabled, rightIcon, ...props }, ref) => {
  return (
    <Item disabled={disabled} ref={ref} className='SelectRoot__item' {...props}>
      <ItemIndicator className='SelectRoot__itemIndicator'>
        <SvgIcon name='checkmark' />
      </ItemIndicator>
      <ItemText>{children}</ItemText>
      {rightIcon && (
        <span className='SelectRoot__rightIcon'>
          <SvgIcon name={rightIcon} />
        </span>
      )}
    </Item>
  );
});
Select.Item.displayName = 'SelectItem';

type SelectGroupProps = RadixGroupProps;

Select.Group = forwardRef<HTMLDivElement, SelectGroupProps>((props, ref) => {
  return <Group {...props} className={clsx('SelectRoot__group', props.className)} ref={ref} />;
});
Select.Group.displayName = 'SelectGroup';

type SelectGroupTitleProps = RadixLabelProps;

Select.GroupTitle = forwardRef<HTMLDivElement, SelectGroupTitleProps>(({ className, ...props }, ref) => {
  return <Label className={clsx('SelectRoot__group-title', className)} {...props} ref={ref} />;
});
Select.GroupTitle.displayName = 'SelectGroupTitle';

const SelectSeparator = () => <Separator className='SelectRoot__separator' />;
Select.Separator = SelectSeparator;
