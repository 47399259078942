import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Paper } from 'src/components/mainComponents/Paper';

import './ColorPicker.scss';

export function ColorPicker({ value = '#FFFFFF', ...props }) {
  const { t } = useTranslation();
  const classname = clsx('ColorPicker', { 'ColorPicker--disabled': props.disabled });
  const colorValue = value.substring(0, 7) ?? '#FFFFFF';

  return (
    <label className={classname}>
      {props.label && <span className='ColorPicker__label'>{props.label}</span>}
      <Paper noPadding fullWidth={false}>
        <div className='ColorPicker__content' style={{ '--color': colorValue }} title={t('ColorPicker.tooltip')}>
          <div className='ColorPicker__input'>
            <input
              type='color'
              value={colorValue}
              onChange={event => props.onChange?.(event.target.value)}
              disabled={props.disabled}
              className='ColorPicker__native-input'
            />
            <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' className='ColorPicker__preview'>
              <desc>{t('ColorPicker.preview_description')}</desc>
              <rect width={20} height={20} fill='currentColor' />
            </svg>
          </div>
          <p className='ColorPicker__code'>{colorValue}</p>
        </div>
      </Paper>
    </label>
  );
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
