import * as filestack from 'filestack-js';

import { getConfig } from 'src/config';

const ALLOWED_FILE_SOURCES = ['local_file_system'];
const filestackClient = filestack.init(getConfig().FILESTACK_API_KEY);

export type FileUploadPickOptions = Pick<filestack.PickerOptions, 'accept' | 'imageMax' | 'storeTo' | 'lang'>;
type FileSecurityPolicy = filestack.Security;

export const FILE_SECURITY_IMAGE_TYPE = {
  AVATAR: 'AVATAR_TYPE',
  BACKGROUND: 'BACKGROUND_TYPE',
} as const;

/**
 * Open a file picker and upload the file chosen by the guest.
 * @param pickOptions Options for the file picker.
 * @returns A promise resolving to the uploaded file metadata.
 */
export async function pickFileAndUpload(pickOptions: FileUploadPickOptions): Promise<filestack.PickerFileMetadata> {
  return new Promise((resolve, reject) => {
    filestackClient
      .picker({
        ...pickOptions,
        fromSources: ALLOWED_FILE_SOURCES,
        onFileUploadFinished: resolve,
        onFileUploadFailed: reject,
      })
      .open();
  });
}

/**
 * Delete a file from the storage.
 *
 * Requires a security policy to authorize the operation.
 * @param handle Handle of the file to delete.
 * @param fileSecurity Security policy to use for deletion.
 */
export async function deleteFile(handle: string, fileSecurity: FileSecurityPolicy) {
  const result = await filestackClient.remove(handle, fileSecurity);

  if (result.status !== 200) {
    throw new Error(result.statusText);
  }
}

/**
 * Get the handle of a file from its URL.
 * @param fileUrl URL of the file.
 * @returns The handle of the file.
 */
export function getFileHandle(fileUrl: string) {
  return fileUrl.split('/').pop() ?? '';
}
