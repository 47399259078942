import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Channel, User as StreamUser } from 'stream-chat';

import { useAccountInfo } from 'src/components/AccountInfoProvider';
import { getConfig } from 'src/config';
import { useAuth } from 'src/user';

import { LiveChatButton } from '.';
import { LiveChatQuery } from './liveChat.types';
import { EXTRANET_CHAT } from './queries';
import { useStreamChatClient } from './useStreamChatClient';

const GET_STREAM_APP_KEY = getConfig().GET_STREAM_API_KEY;

export function LiveChatInitializer() {
  const { auth } = useAuth();
  const { academyHash } = useAccountInfo();
  const [channels, setChannels] = useState<Channel[]>([]);
  const user: StreamUser = {
    id: `${academyHash}-${auth?.guestInfo?.id}`,
  };

  const isChatAvailable = auth?.guestInfo?.type === 'trainee' || auth?.guestInfo?.type === 'instructor';

  const {
    data: liveChatData,
    loading: isTokenLoading,
    error: tokenError,
  } = useQuery<LiveChatQuery>(EXTRANET_CHAT, {
    skip: !isChatAvailable,
  });

  const {
    client: chatClient,
    isLoading: isChatClientLoading,
    error: chatClientError,
  } = useStreamChatClient({
    apiKey: GET_STREAM_APP_KEY,
    user,
    tokenOrProvider: liveChatData?.liveChat?.token,
  });

  useEffect(() => {
    fetchUserChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatClient]);

  const error = tokenError ?? chatClientError;
  const isLoading = isTokenLoading || isChatClientLoading;

  if (error) {
    console.error('Connection to chat failed', error);
  }

  if (
    !chatClient ||
    error ||
    !isChatAvailable ||
    isLoading ||
    !liveChatData ||
    liveChatData.liveChat.token === null ||
    channels.length === 0
  ) {
    return null;
  }

  const initialUnreadCount = (chatClient.user?.unread_count as number) ?? 0;

  return (
    <LiveChatButton
      client={chatClient}
      unreadCount={initialUnreadCount}
      userSetting={liveChatData.liveChat.userSetting}
      channelTrainingSessions={liveChatData.liveChat.channelTrainingSessions}
    />
  );

  /**
   * Fetch the list of channels the user is a member of, or undefined if the
   * chat client is not initialized.
   * @returns The list of channels or undefined.
   */
  async function fetchUserChannels() {
    if (!chatClient) {
      return [];
    }

    try {
      const channels = await chatClient.queryChannels({
        type: 'messaging',
        members: { $in: [user.id] },
      });
      setChannels(channels);
    } catch (error) {
      console.error('Failed to fetch user channels', error);

      // In case of failure, show the chat button
      setChannels([undefined as unknown as Channel]);
    }
  }
}
