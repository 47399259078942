import { useCallback, useState } from 'react';

export default function useDisclosure(init = false) {
  const [isOpen, setIsOpen] = useState(init);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return { open, close, isOpen, setIsOpen };
}
