import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';

import { withData } from 'src/support/page';
import { withAuth } from 'src/user';

import { TrainingSessionSelect, TrainingSessionSelectLoader } from './TrainingSessionSelect';

const TRAINEE_TRAINING_SESSIONS = gql`
  query trainingSessions($id: ID!) {
    trainee(id: $id) {
      training_sessions {
        id
        name
        custom_name
      }
    }
  }
`;

const withGraphQlData = graphql(TRAINEE_TRAINING_SESSIONS, {
  name: 'user',
  options: props => {
    return {
      variables: {
        id: props?.auth?.guestInfo?.id,
      },
    };
  },
});

// prettier-ignore
export const TraineeTrainingSessionSelect =
  withAuth(
    withGraphQlData(
      withData({request: "user", data: 'trainee'})(
        TrainingSessionSelect, { loading: TrainingSessionSelectLoader })));
