import { useContext } from 'react';

import { ExtranetFeatures, ExtranetFeaturesContext } from './ExtranetFeaturesContext';

/**
 * Get the list of optional features with their activation state.
 *
 * @example
 * function MyComponent() {
 *   const { hasUiv2 } = useExtranetFeatures();
 *
 *   if (hasUiv2) {
 *     return <ExtranetV2 />
 *   }
 *   else {
 *     return <ExtranetV1 />
 *   }
 * }
 *
 * @returns {ExtranetFeatures} State of the optional features of the extranet.
 */
export function useExtranetFeatures(): ExtranetFeatures {
  const context = useContext(ExtranetFeaturesContext);

  if (!context) {
    throw new Error('useExtranetFeatures must be used within ExtranetFeaturesContextProvider');
  }

  return context;
}
