import { useEffect } from 'react';

import { useExtranetFeatures } from 'src/contexts/useExtranetFeatures';
import { GUEST_TYPE } from 'src/user';

import { IS_EXTRANET_V2_ENABLED_KEY, useIsExtranetV2EnabledLocally } from './useIsExtranetV2EnabledLocally';

export function UIv2Guard(props) {
  const { hasUiv2, isUiv2Activable } = useExtranetFeatures();
  const isExtranetV2EnabledLocally = useIsExtranetV2EnabledLocally() && props.auth?.guestInfo?.type?.toLowerCase() === GUEST_TYPE.USER;

  useEffect(() => {
    if (hasUiv2 && isExtranetV2EnabledLocally) {
      localStorage.removeItem(IS_EXTRANET_V2_ENABLED_KEY);
    }
  }, [hasUiv2, isExtranetV2EnabledLocally]);

  const isV2Active = isUiv2Activable && (hasUiv2 || isExtranetV2EnabledLocally);
  return props.children({
    isV2Active,
    isV2Activable: isUiv2Activable,
  });
}
