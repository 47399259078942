import { useState } from 'react';
import { Trans } from 'react-i18next';

import { Button } from 'src/components/mainComponents/Button';
import { Callout } from 'src/components/mainComponents/Callout';

import './NotificationPermissionRequest.scss';

const REQUEST_DISMISSED_STORAGE_KEY = 'notification_permission_request_dismissed';

type NotificationPermissionRequestProps = {
  onPermissionRequested?: (permission: NotificationPermission) => void;
};

export function NotificationPermissionRequest(props: NotificationPermissionRequestProps) {
  const [showRequest, setShowRequest] = useState(isPermissionUnknown());

  if (!showRequest) {
    return null;
  }

  return (
    <Callout onDismiss={dismissRequest} className='NotificationPermissionRequest'>
      <p>
        <Trans
          i18nKey='LiveChat.request_notifications_permission'
          components={{
            link1: (
              <Button variant='text' size='sm' onClick={requestPermission} className='NotificationPermissionRequest__request-button' />
            ),
          }}
        />
      </p>
    </Callout>
  );

  function isPermissionUnknown() {
    if (!('Notification' in window)) {
      return false;
    }

    return Notification.permission === 'default' && sessionStorage.getItem(REQUEST_DISMISSED_STORAGE_KEY) === null;
  }

  function dismissRequest() {
    sessionStorage.setItem(REQUEST_DISMISSED_STORAGE_KEY, Date.now().toString());
    setShowRequest(false);
  }

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    props.onPermissionRequested?.(permission);
    setShowRequest(false);
  }
}
