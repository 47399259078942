import { useEffect, useState } from 'react';

export default function useIsDocumentVisible() {
  const [isDocumentVisible, setIsDocumentVisible] = useState(true);

  useEffect(() => {
    function handleIsDocumentVisible() {
      setIsDocumentVisible(document.visibilityState === 'visible');
    }

    document.addEventListener('visibilitychange', handleIsDocumentVisible);
    return () => {
      document.removeEventListener('visibilitychange', handleIsDocumentVisible);
    };
  }, []);

  return isDocumentVisible;
}
