import { clsx } from 'clsx';
import PropTypes from 'prop-types';

import { Heading } from 'src/components/mainComponents/Heading';

import './Paper.scss';

export function Paper({ as, children, className: additionalClassName, noPadding, fullWidth, ...htmlAttributes }) {
  const Component = as ?? 'div';
  const className = clsx(
    'Paper',
    {
      'Paper--no-padding': noPadding,
      'Paper--full-width': fullWidth,
    },
    additionalClassName
  );

  return (
    <Component className={className} {...htmlAttributes}>
      {children}
    </Component>
  );
}

Paper.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

Paper.defaultProps = {
  fullWidth: true,
  noPadding: false,
  className: '',
};

const PaperTitle = props => {
  return (
    <Heading level={4} as='h4' className='Paper__title'>
      {props.children}
    </Heading>
  );
};
Paper.Title = PaperTitle;

Paper.Title.propTypes = {
  children: PropTypes.node,
};

const PaperActions = props => {
  return <div className='Paper__actions'>{props.children}</div>;
};
Paper.Actions = PaperActions;

Paper.Actions.propTypes = {
  children: PropTypes.node,
};
