import { ComponentPropsWithoutRef } from 'react';
import { ThreadHeader } from 'stream-chat-react';

import { Button } from 'src/components/mainComponents/Button';

import './CustomThreadHeader.scss';

export function CustomThreadHeader(props: ComponentPropsWithoutRef<typeof ThreadHeader>) {
  return (
    <div className='CustomThreadHeader'>
      <Button
        variant='text'
        size='xl'
        icon='arrow--left'
        onClick={props.closeThread}
        className='CustomThreadHeader__close-thread-button'
      />
      <ThreadHeader {...props} />
    </div>
  );
}
