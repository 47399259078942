import { PickerFileMetadata } from 'filestack-js';

import { useAccountInfo } from 'src/components/AccountInfoProvider';
import { deleteFile, FILE_SECURITY_IMAGE_TYPE, getFileHandle, isGuestAllowedToUpdatePicture, uploadGuestPicture } from 'src/guestFile';
import { apolloClient } from 'src/store/apolloClient';
import { useAuth } from 'src/user/useAuth';

import { FILESTACK_SECURITY_QUERY, UPDATE_GUEST_PICTURE_MUTATION } from './queries';

export function useGuestPictureUpdater() {
  const { id: academyId } = useAccountInfo();
  const { auth, setPictureUrl } = useAuth();

  if (!auth?.guestInfo) {
    return {
      canReplace: () => false,
      replace: () => {},
      deletePicture: () => {},
    };
  }

  return {
    canReplace,
    replace,
    deletePicture,
  };

  /**
   * Check whether the current guest can replace their picture.
   * @returns Whether the current guest can replace their picture.
   */
  function canReplace() {
    return isGuestAllowedToUpdatePicture(auth!.guestInfo!.type);
  }

  /**
   * Replace the guest's picture.
   *
   * Calling this will immediately show a file picker allowing the guest to select their new picture.
   * The picture will be uploaded to storage, and the backend will be updated with the newly
   * uploaded picture. The previous picture will then be removed from storage. Finally, the auth
   * context will be updated with the new picture.
   */
  async function replace() {
    if (!isGuestAllowedToUpdatePicture(auth!.guestInfo!.type)) {
      throw new Error(`Guest with type ${auth!.guestInfo!.type} is not allowed to replace its picture.`);
    }

    try {
      const uploadedFileMetadata = await uploadGuestPicture(academyId, auth!.guestInfo!.type);
      const previousHandle = getFileHandle(auth!.guestInfo!.logo?.url ?? '');
      const previousFileSecurityPolicy = await fetchFileSecurityPolicy(previousHandle);

      await updateUserPictureInBackend(uploadedFileMetadata);

      if (previousFileSecurityPolicy) {
        await deleteFile(previousHandle, previousFileSecurityPolicy);
      }

      setPictureUrl(uploadedFileMetadata.url);

      return uploadedFileMetadata.url;
    } catch (error) {
      console.error('Failed replacing picture', error);
      throw error;
    }
  }

  async function deletePicture() {
    const avatar: string = auth?.guestInfo?.logo?.url ?? '';
    try {
      const fileHandle: string = getFileHandle(avatar);
      const fileSecurityAndPolicy = await fetchFileSecurityPolicy(fileHandle);

      await updateUserPictureInBackend(null);

      if (fileSecurityAndPolicy) await deleteFile(fileHandle, fileSecurityAndPolicy);

      setPictureUrl('');
    } catch (error) {
      console.error('Could not delete guest avatar', error);
      throw error;
    }
  }
}

async function fetchFileSecurityPolicy(handle: string | undefined) {
  if (!handle) {
    return null;
  }

  const { data, error } = await apolloClient.query({
    query: FILESTACK_SECURITY_QUERY,
    fetchPolicy: 'network-only',
    variables: { handle: handle, imageType: FILE_SECURITY_IMAGE_TYPE.AVATAR },
  });

  if (error) {
    throw new Error(error.message);
  }

  if (!data.filestackSecurity.policy || !data.filestackSecurity.signature) {
    throw new Error('File security policy is empty or incomplete');
  }

  return data.filestackSecurity;
}

async function updateUserPictureInBackend(newFile: PickerFileMetadata | null) {
  const logo = createLogoObject(newFile);

  const { data, errors } = await apolloClient.mutate({
    mutation: UPDATE_GUEST_PICTURE_MUTATION,
    variables: {
      guestAvatarConfig: {
        logo: logo,
      },
    },
  });

  if (errors) {
    throw errors;
  }

  return data.guestAvatarConfig;
}

const createLogoObject = (file: PickerFileMetadata | null) => {
  if (!file) return null;

  return {
    filename: file.filename,
    url: file.url,
    size: file.size,
    mime: file.mimetype,
  };
};
