import PropTypes from 'prop-types';

import { SvgIcon } from 'src/components/mainComponents/SvgIcon';
import 'src/scss/mainComponents/buttons/Button.scss';

export const ActionButton = props => {
  const { size, label, ariaLabel, icon, rightIcon, clickFn, type, className = '', ...forward } = props;
  const sizeClass = (size && `button--${size}`) || '';
  const iconClass = (icon && `button--has-icon`) || '';
  const labelClass = (label && `button--has-label`) || '';
  const typeClass = (type && `button--${type}`) || '';
  const stateClass = (!clickFn && 'button--disabled') || '';
  const normalizedAriaLabel = ariaLabel ?? label;

  normalizedAriaLabel ?? console.error('no ARIA label provided for button');

  const sharedProps = {
    className: `button ${className} ${sizeClass} ${iconClass} ${labelClass} ${typeClass} ${stateClass}`,
    'aria-label': normalizedAriaLabel,
    title: label,
    onClick: clickFn,
    ...forward,
  };

  return (
    <button type='button' {...sharedProps}>
      {icon && <SvgIcon name={icon} className='icon' />}
      {label && (
        <label className='button__label'>
          <p>{label}</p>
        </label>
      )}
      {rightIcon && <SvgIcon icon={rightIcon} className='button__rightIcon' />}
    </button>
  );
};

ActionButton.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  clickFn: PropTypes.func,
  type: PropTypes.oneOf(['secondary', 'large', 'blue']),
  rightIcon: PropTypes.string,
  className: PropTypes.string,
};

ActionButton.defaultProps = {};
