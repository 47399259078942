import PropTypes from 'prop-types';

import Icon from 'src/components/mainComponents/Icon';
import 'src/scss/mainComponents/buttons/Button.scss';

export const SubmitButton = props => {
  const { size, label, ariaLabel, icon, type, clickFn, className = '', disabled = false, ...forward } = props;
  const sizeClass = (size && `button--${size}`) || '';
  const iconClass = (icon && `button--has-icon`) || '';
  const labelClass = (label && `button--has-label`) || '';
  const typeClass = (type && `button--${type}`) || '';
  const stateClass = (disabled && `button--disabled`) || '';

  const normalizedAriaLabel = ariaLabel ?? label;

  const sharedProps = {
    className: `button ${className} ${sizeClass} ${iconClass} ${labelClass} ${typeClass} ${stateClass}`,
    'aria-label': normalizedAriaLabel,
    title: label,
    ...forward,
    onClick: clickFn,
    disabled,
  };

  return (
    <button type='submit' {...sharedProps}>
      {icon && <Icon icon={icon} />}
      {label && (
        <label className='button__label'>
          <p>{label}</p>
        </label>
      )}
    </button>
  );
};

SubmitButton.propTypes = {
  icon: PropTypes.elementType,
  size: PropTypes.oneOf(['small', 'large']),
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  clickFn: PropTypes.func,
  type: PropTypes.oneOf(['secondary', 'large']),
  dispatchEvent: PropTypes.bool,
};

SubmitButton.defaultProps = {};
