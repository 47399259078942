import { useAccountInfo } from 'src/components/AccountInfoProvider';

export function useBrowserNotification(requestPermissionIfNotGranted = true) {
  const { company, logo: academyLogo } = useAccountInfo();

  return async function (content: string) {
    showBrowserNotification(requestPermissionIfNotGranted, company, content, academyLogo.url);
  };
}

async function showBrowserNotification(requestPermissionIfNotGranted: boolean, title: string, content: string, icon?: string) {
  if (await canShowNotification(requestPermissionIfNotGranted)) {
    new Notification(title, {
      body: content,
      icon,
    });
  }
}

async function canShowNotification(requestPermissionIfNotGranted: boolean) {
  if (!('Notification' in window)) {
    return false;
  }

  if (Notification.permission === 'granted') {
    return true;
  }

  if (requestPermissionIfNotGranted) {
    const permission = await Notification.requestPermission();
    return permission === 'granted';
  }

  return false;
}
