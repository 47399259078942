import clsx from 'clsx';

import { SvgIcon } from 'src/components/mainComponents/SvgIcon';
import 'src/scss/mainComponents/Icon.scss';

export const Icon = ({ icon, className = '' }) => {
  const wrapperClassname = clsx('icon', className);
  return <SvgIcon name={icon} className={wrapperClassname} />;
};

export default Icon;
