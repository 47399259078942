import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AlertDialog, AlertDialogActions, AlertDialogContent, AlertDialogTitle } from 'src/components/mainComponents/AlertDialog';
import { ActionButton } from 'src/components/mainComponents/buttons';
import Icon from 'src/components/mainComponents/Icon';
import { useAuth } from 'src/user';
import { GUEST_TYPE } from 'src/user';

import uiPreview from './ui_preview.svg';
import { IS_EXTRANET_V2_ENABLED_KEY } from './useIsExtranetV2EnabledLocally';
import './V2ActivationDialog.scss';

export function V2ActivationDialog(props) {
  const [enableExtranetV2, { error: enableExtranetV2Error, loading: enableExtranetV2Loading }] = useMutation(ENABLE_EXTRANET_V_2);

  const isDialogAlreadyShown = hasDialogBeenShown();

  const { auth } = useAuth();
  const { t } = useTranslation();
  const [isV2ActivationPopupOpen, setV2ActivationPopupOpen] = useState(
    !isDialogAlreadyShown && auth?.guestInfo?.type?.toLowerCase() === GUEST_TYPE.USER
  );

  useEffect(() => {
    if (!isDialogAlreadyShown && auth?.guestInfo?.type?.toLowerCase() === GUEST_TYPE.USER) {
      setV2ActivationPopupOpen(true);
    }
  }, [auth?.guestInfo?.type, isDialogAlreadyShown]);

  if (!props.isActivable) {
    return props.children;
  }

  return (
    <>
      {props.children}
      <AlertDialog open={isV2ActivationPopupOpen}>
        <AlertDialogTitle>{t('V2Activation.dialog_title')}</AlertDialogTitle>
        <AlertDialogContent>
          <div className='V2ActivationDialog'>
            <p>
              <strong>{t('V2Activation.dialog_subtitle')}</strong>
            </p>
            <img
              src={uiPreview}
              width='100%'
              height='auto'
              alt={t('V2Activation.dialog_preview_alt')}
              title={t('V2Activation.dialog_preview_alt')}
            />
            <Trans
              i18nKey='V2Activation.dialog_content'
              components={{
                ul: <ul className='V2ActivationDialog__list' />,
                li: <li className='V2ActivationDialog__list-item' />,
              }}
            />
            <div className='V2ActivationDialog__auto-activation-warning'>
              <Icon icon='warning--circle' />
              <p>
                <Trans i18nKey='V2Activation.dialog_auto_activation_warning' />
              </p>
            </div>
          </div>
          {enableExtranetV2Error && (
            <p className='V2ActivationDialog__error' role='alert'>
              {t('V2Activation.error')}
            </p>
          )}
        </AlertDialogContent>
        <AlertDialogActions>
          <ActionButton
            disabled={enableExtranetV2Loading}
            label={t('V2Activation.dialog_action_wait')}
            type='secondary'
            clickFn={discardActivation}
          />
          <ActionButton
            disabled={enableExtranetV2Loading}
            label={t('V2Activation.dialog_action_activate')}
            type='large'
            clickFn={activateV2}
          />
        </AlertDialogActions>
      </AlertDialog>
    </>
  );

  async function activateV2() {
    markDialogShown();
    try {
      await enableExtranetV2();
      setV2ActivationPopupOpen(false);
      localStorage.setItem(IS_EXTRANET_V2_ENABLED_KEY, 'true');
      window.location = `${window.location.origin}/admin_page`;
    } catch (err) {
      console.error('Could not enable extranet version 2', err);
    }
  }

  function discardActivation() {
    markDialogShown();
    setV2ActivationPopupOpen(false);
  }
}

V2ActivationDialog.propTypes = {
  children: PropTypes.node,
  isActivable: PropTypes.bool,
};

const DIALOG_SHOWN_STORAGE_KEY = 'v2_activation_dialog_shown';

function hasDialogBeenShown() {
  // Use session storage to remember dialog shown because <UrlButton>s
  // don't use the router so the app is completely reloaded.
  return Boolean(sessionStorage.getItem(DIALOG_SHOWN_STORAGE_KEY));
}

function markDialogShown() {
  sessionStorage.setItem(DIALOG_SHOWN_STORAGE_KEY, true);
}

const ENABLE_EXTRANET_V_2 = gql`
  mutation enable_extranet_v_2 {
    enable_extranet_v_2
  }
`;
