import { useMutation } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Event, StreamChat } from 'stream-chat';

import * as AlertDialog from 'src/components/mainComponents/AlertDialog';
import { Badge } from 'src/components/mainComponents/Badge';
import { Button } from 'src/components/mainComponents/Button';
import { useBrowserNotification } from 'src/hooks/useBrowserNotification';
import { useThrottle } from 'src/hooks/useThrottle';

import { LiveChat } from '../LiveChat';
import type {
  LiveChatChannelTrainingSession,
  LiveChatUserSetting,
  UpdateLiveChatUserSettingMutation,
  UpdateLiveChatUserSettingVariables,
} from '../liveChat.types';
import { NotificationPermissionRequest } from '../NotificationPermissionRequest';
import { UPDATE_USER_SETTING } from '../queries';
import './LiveChatButton.scss';

type LiveChatButtonProps = {
  client: StreamChat;
  unreadCount: number;
  userSetting: LiveChatUserSetting;
  channelTrainingSessions: LiveChatChannelTrainingSession[];
};

export function LiveChatButton(props: LiveChatButtonProps) {
  const showBrowserNotification = useBrowserNotification(false);
  const { t } = useTranslation();
  const isChatOpenRef = useRef(false);
  const [unreadCount, setUnreadCount] = useState(props.unreadCount);
  const [throttledShowMessageNotification] = useThrottle(30_000, showNewMessageNotification);

  const [persistUserSetting, { data: updatedUserSetting }] = useMutation<
    UpdateLiveChatUserSettingMutation,
    UpdateLiveChatUserSettingVariables
  >(UPDATE_USER_SETTING);

  useEffect(() => {
    const subscription = props.client.on(handleChatEvent);

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.client]);

  return (
    <AlertDialog.AlertDialog
      dismissable
      fullScreen
      trigger={dialogTrigger()}
      onOpenChange={(open: boolean) => (isChatOpenRef.current = open)}
    >
      <AlertDialog.AlertDialogContent>
        <NotificationPermissionRequest />
        <LiveChat
          client={props.client}
          userSetting={updatedUserSetting?.updateLiveChatUserSetting ?? props.userSetting}
          onEmailReminderSettingChanged={saveUserSetting}
          channelTrainingSessions={props.channelTrainingSessions}
        />
      </AlertDialog.AlertDialogContent>
    </AlertDialog.AlertDialog>
  );

  function dialogTrigger() {
    return (
      <div className='LiveChatButton__trigger'>
        <Button rounded icon='speech-balloon' color='secondary' size='2xl' />
        {unreadCount > 0 ? (
          <div className='LiveChatButton__unread-badge'>
            <Badge value={unreadCount} max={9} />
          </div>
        ) : null}
      </div>
    );
  }

  function handleChatEvent(event: Event) {
    if (event.total_unread_count !== undefined) {
      setUnreadCount(event.total_unread_count);
    }

    if ((event.type === 'notification.message_new' || event.type === 'message.new') && !isChatOpenRef.current) {
      throttledShowMessageNotification(event.message?.user?.name);
    }
  }

  function showNewMessageNotification(name: string | undefined) {
    showBrowserNotification(
      name
        ? t('LiveChat.new_message_notification_content_with_author', { name })
        : t('LiveChat.new_message_notification_content_without_author')
    );
  }

  async function saveUserSetting(isEnabled: boolean) {
    await persistUserSetting({
      variables: {
        setting: {
          emailReminderEnabled: isEnabled,
        },
      },
    });
  }
}
