import clsx from 'clsx';

import { Button } from '../Button';
import './Callout.scss';

type CalloutProps = {
  /**
   * Called when the callout is dismissed. If not provided, the callout
   * is not dismissable.
   */
  onDismiss?: () => void;
  children: React.ReactNode;

  /**
   * Additional classes to apply to the callout container.
   */
  className?: string;
};

export function Callout(props: CalloutProps) {
  return (
    <div className={clsx('Callout', props.className)}>
      <div className='Callout__content'>{props.children}</div>
      {props.onDismiss && <Button onClick={props.onDismiss} icon='cross' variant='text' />}
    </div>
  );
}
