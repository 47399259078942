import { useEffect, useRef } from 'react';

/**
 * Throttle calls to a callback.
 *
 * The throttled version will ignore calls until the throttling time has passed.
 * Throttling time starts when a callback is executed.
 *
 * One argument can be passed to the throttled callback.
 *
 * The reset function can be used to immediately execute the next call, then
 * throttling starts again.
 * @param milliseconds Throttling time in milliseconds between allowed calls.
 * @param callback Callback to execute.
 * @returns An array with the throttled callback and a reset function.
 */
export function useThrottle<T>(milliseconds: number, callback: (payload?: T) => void) {
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();
  const isThrottlingRef = useRef(false);

  // Clean any ongoing timeout when the component is unmounted
  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        window.clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  /**
   * Call the original callback if it is not currently throttled.
   * @param payload Optional payload that will be passed to the original callback.
   */
  function throttledCallback(payload?: T) {
    if (isThrottlingRef.current) {
      return;
    }

    callback(payload);
    isThrottlingRef.current = true;
    timeoutIdRef.current = setTimeout(() => (isThrottlingRef.current = false), milliseconds);
  }

  /**
   * Reset the throttling, allowing the next call to be executed immediately.
   */
  function resetThrottling() {
    if (timeoutIdRef.current) {
      window.clearTimeout(timeoutIdRef.current);
    }

    isThrottlingRef.current = false;
  }

  return [throttledCallback, resetThrottling] as const;
}
