import * as RadixAlertDialog from '@radix-ui/react-alert-dialog';
import clsx from 'clsx';

import { Button } from 'src/components/mainComponents/Button';
import { Heading } from 'src/components/mainComponents/Heading';
import { useColorConfiguration } from 'src/components/v2/ColorConfiguration';

import './AlertDialog.scss';

type AlertDialogProps = RadixAlertDialog.AlertDialogProps & {
  trigger: RadixAlertDialog.DialogTriggerProps['children'];
  fullScreen?: boolean;

  /**
   * If `true`, the dialog can be dismissed by pressing the esc key or clicking
   * the closing button in the top right corner.
   * You don't need to provide any action in that case.
   */
  dismissable?: boolean;
};

/***
 * A modal dialog interrupting the user with important content and expects a response.
 *
 * # Examples
 *
 * ## With a trigger
 *
 * ```
 * <AlertDialog trigger={<button>Unsubscribe</button>}>
 *     <AlertDialogTitle>Are you sure you want to unsubscribe?</AlertDialogTitle>
 *     <AlertDialogContent>
 *         If you confirm, you will not have access to the app and any of your data anymore.<br/>
 *         You will be able to subscribe again later to gain access back to your data.
 *     </AlertDialogContent>
 *     <AlertDialogActions>
 *         <AlertDialogCancel><button>Keep my subscription</button></AlertDialogCancel>
 *         <AlertDialogAction><button>Proceed and unsubscribe</button></AlertDialogAction>
 *     </AlertDialogActions>
 * </AlertDialog>
 * ```
 *
 * ## Without a trigger
 *
 * ```
 * <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
 *     <AlertDialogTitle>Are you sure you want to unsubscribe?</AlertDialogTitle>
 *     <AlertDialogContent>
 *         If you confirm, you will not have access to the app and any of your data anymore.<br/>
 *         You will be able to subscribe again later to gain access back to your data.
 *     </AlertDialogContent>
 *     <AlertDialogActions>
 *         <AlertDialogCancel><button>Keep my subscription</button></AlertDialogCancel>
 *         <AlertDialogAction><button>Proceed and unsubscribe</button></AlertDialogAction>
 *     </AlertDialogActions>
 * </AlertDialog>
 * ```
 */
export function AlertDialog(props: AlertDialogProps) {
  const { trigger, fullScreen, dismissable, ...rootProps } = props;
  const { containerRef: colorConfigurationContainerRef } = useColorConfiguration();
  const contentClassName = clsx('AlertDialog__content', { 'AlertDialog__content--full-screen': fullScreen });

  return (
    <RadixAlertDialog.Root {...rootProps}>
      <RadixAlertDialog.Trigger asChild>{trigger}</RadixAlertDialog.Trigger>
      <RadixAlertDialog.Portal container={colorConfigurationContainerRef}>
        <RadixAlertDialog.Overlay className='AlertDialog__overlay' />
        <RadixAlertDialog.Content onEscapeKeyDown={maybePreventClosing} className={contentClassName}>
          {dismissable && (
            <RadixAlertDialog.Cancel asChild>
              <Button icon='cross' variant='text' className='AlertDialog__dismiss-button' />
            </RadixAlertDialog.Cancel>
          )}
          {props.children}
        </RadixAlertDialog.Content>
      </RadixAlertDialog.Portal>
    </RadixAlertDialog.Root>
  );

  function maybePreventClosing(event: KeyboardEvent) {
    if (dismissable) {
      return;
    }

    event.preventDefault();
  }
}

type AlertDialogTitleProps = {
  children: React.ReactNode;
};

export function AlertDialogTitle(props: AlertDialogTitleProps) {
  return (
    <RadixAlertDialog.Title asChild>
      <Heading level={3}>{props.children}</Heading>
    </RadixAlertDialog.Title>
  );
}

type AlertDialogContentProps = {
  children: React.ReactNode;
};

export function AlertDialogContent(props: AlertDialogContentProps) {
  return (
    <RadixAlertDialog.Description asChild>
      <>{props.children}</>
    </RadixAlertDialog.Description>
  );
}

type AlertDialogActionsProps = {
  children: React.ReactNode;
};

export function AlertDialogActions(props: AlertDialogActionsProps) {
  return <div className='AlertDialog__actions'>{props.children}</div>;
}

type AlertDialogActionProps = {
  children: React.ReactNode;
};

export function AlertDialogAction(props: AlertDialogActionProps) {
  return <RadixAlertDialog.Action asChild>{props.children}</RadixAlertDialog.Action>;
}

type AlertDialogCancelProps = {
  children: React.ReactNode;
};

export function AlertDialogCancel(props: AlertDialogCancelProps) {
  return <RadixAlertDialog.Cancel asChild>{props.children}</RadixAlertDialog.Cancel>;
}
