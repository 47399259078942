import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useAddToconnectionLogState } from 'src/components/mainComponents/ConnectionLogDataContex/ConnectionLogDataContext';
import { SidebarNavigationContent } from 'src/components/mainComponents/SidebarNavigationContent';
import { HeaderBar } from 'src/components/v2/HeaderBar';
import useConnectionLogMutation from 'src/components/v2/layouts/LeftMenuLayout/useConnectionLogMutation';

import './LeftMenuLayout.scss';

export function LeftMenuLayout(props) {
  const params = useParams();
  const trainingSessionId = params.training_session_id;
  const addToconnectionLogStateRef = useRef();
  addToconnectionLogStateRef.current = useAddToconnectionLogState();

  const addToConnectionLogState = useCallback(props => {
    return addToconnectionLogStateRef.current(props);
  }, []);

  useConnectionLogMutation(trainingSessionId);

  useEffect(() => {
    addToConnectionLogState({ training_session_id: trainingSessionId, activity_id: undefined, module_id: undefined });
  }, [trainingSessionId, addToConnectionLogState]);

  return (
    <div className='V2_LeftMenuLayout'>
      <HeaderBar
        trainingSessionId={trainingSessionId}
        forceShowMenuTrigger={props.forceHideMenu}
        className='V2_LeftMenuLayout__header'
      />
      <div className='V2_LeftMenuLayout__body'>
        {!props.forceHideMenu && (
          <div className='V2_LeftMenuLayout__nav'>
            <SidebarNavigationContent trainingSessionId={trainingSessionId} />
          </div>
        )}
        <main className={clsx('V2_LeftMenuLayout__content', { 'V2_LeftMenuLayout__content--no-menu': props.forceHideMenu })}>
          {props.children}
          <div className='V2_LeftMenuLayout__bottom-spacer'></div>
        </main>
      </div>
    </div>
  );
}

LeftMenuLayout.propTypes = {
  forceHideMenu: PropTypes.bool,
  children: PropTypes.node,
};
