import { useTranslation } from 'react-i18next';
import { MessageInput as StreamMessageInput, useChannelStateContext } from 'stream-chat-react';

import { Callout } from 'src/components/mainComponents/Callout';

import './MessageInput.scss';

export function MessageInput() {
  const { t } = useTranslation();
  const channelState = useChannelStateContext();

  if (channelState.channel.data?.frozen) {
    return (
      <div className='MessageInput'>
        <Callout>{t('LiveChat.channel_is_frozen')}</Callout>
      </div>
    );
  }

  return <StreamMessageInput />;
}
