import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { type ChannelFilters, type Channel as StreamChatChannel } from 'stream-chat';
import { ChannelList, useChatContext } from 'stream-chat-react';

import { Select } from 'src/components/mainComponents/Select';

import { CustomChannelPreview } from '../CustomChannelPreview';
import { EmailReminderButton, EmailReminderButtonProps } from '../EmailReminderButton';
import { groupChannelsPerTrainingSession } from '../liveChat.business';
import { LiveChatChannelTrainingSession, LiveChatUserSetting } from '../liveChat.types';
import './ChannelsList.scss';
import { useChannelFilterOptions } from './useChannelFilterOptions';

type ChannelsListProps = {
  userId: string;
  userSetting?: LiveChatUserSetting;
  channelTrainingSessions: LiveChatChannelTrainingSession[];
  onEmailReminderSettingChanged: EmailReminderButtonProps['onChanged'];
  onClose: () => void;
};

export function ChannelsList(props: ChannelsListProps) {
  const { t } = useTranslation();
  const params = useParams();
  const chatContext = useChatContext();
  const [selectedTrainingSession, setSelectedTrainingSession] = useState(params.training_session_id ?? '');
  const trainingSessionToChannelsMap = groupChannelsPerTrainingSession(props.channelTrainingSessions);

  const filters: ChannelFilters = {
    type: 'messaging',
    members: { $in: [props.userId] },
  };

  const channelFilterOptions = useChannelFilterOptions(trainingSessionToChannelsMap, filters, chatContext.client);

  return (
    <div className='ChannelsList'>
      <div className='ChannelsList__filter'>
        <Select
          label={t('LiveChat.filter_channels_list') ?? undefined}
          value={selectedTrainingSession}
          onChange={setSelectedTrainingSession}
        >
          <Select.Item value={''}>{t('LiveChat.channels_list_filter_all_sessions')}</Select.Item>
          {channelFilterOptions.map(trainingSession => (
            <Select.Item key={trainingSession.id} value={trainingSession.id}>
              {trainingSession.name}
            </Select.Item>
          ))}
        </Select>
      </div>
      <ChannelList
        filters={filters}
        Preview={previewProps => (
          <CustomChannelPreview userId={props.userId} previewProps={previewProps} onChannelSelected={props.onClose} />
        )}
        channelRenderFilterFn={filterChannels}
      />
      {props.userSetting && (
        <EmailReminderButton isEnabled={props.userSetting.emailReminderEnabled} onChanged={props.onEmailReminderSettingChanged} />
      )}
    </div>
  );

  function filterChannels(channels: StreamChatChannel[]): StreamChatChannel[] {
    if (selectedTrainingSession === '') {
      return channels;
    }

    const allowedChannels = (trainingSessionToChannelsMap.get(selectedTrainingSession)?.channels ?? []).map(
      channel => channel.channelId
    );

    return channels.filter(channel => allowedChannels.includes(channel.id ?? ''));
  }
}
