import { useContext } from 'react';

import { ColorConfigurationContext } from './ColorConfigurationProvider';

export function useColorConfiguration() {
  const context = useContext(ColorConfigurationContext);

  if (!context) {
    throw new Error('useColorConfiguration must be used within a ColorConfigurationProvider');
  }

  return context;
}
