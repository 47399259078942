import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Select } from 'src/components/mainComponents/Select';
import { sortTrainingSessions, trainingSessionName } from 'src/controllers/moduleController';

export function TrainingSessionSelect(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    currentSessionId,
    user: { training_sessions: trainingSessions },
  } = props;

  const sortedTrainingSessions = sortTrainingSessions(trainingSessions);

  return (
    <Select defaultValue={currentSessionId} onChange={goToSession} label={t('TrainingSessionSelect.label')}>
      {sortedTrainingSessions.map(session => (
        <Select.Item value={session.id} key={session.id}>
          {trainingSessionName(session)}
        </Select.Item>
      ))}
    </Select>
  );

  function goToSession(sessionId) {
    navigate(`/ts/${sessionId}`);
  }
}

TrainingSessionSelect.propTypes = {
  currentSessionId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    training_sessions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export function TrainingSessionSelectLoader() {
  return (
    <Select disabled>
      <option />
    </Select>
  );
}
