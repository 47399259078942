import { gql } from '@apollo/client';

export const FILESTACK_SECURITY_QUERY = gql`
  query filestackSecurity($handle: String!, $imageType: String!) {
    filestackSecurity(handle: $handle, imageType: $imageType) {
      policy
      signature
    }
  }
`;

export const UPDATE_GUEST_PICTURE_MUTATION = gql`
  mutation ($guestAvatarConfig: GuestAvatarConfigInput!) {
    updateGuestAvatarConfig(guestAvatarConfig: $guestAvatarConfig) {
      logo {
        id
        filename
        url
        size
        mime
      }
    }
  }
`;
