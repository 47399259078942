import { v4 as uuidV4 } from 'uuid';

import { getConfig } from 'src/config';
import { FileUploadPickOptions, pickFileAndUpload } from 'src/guestFile';
import { getLocale } from 'src/translations/i18n';
import { GuestType } from 'src/user';

export const ALLOWED_GUEST_TYPES_FOR_PICTURE_UPDATE = ['instructor', 'trainee'] as const satisfies GuestType[];
type PictureUpdateGuestType = (typeof ALLOWED_GUEST_TYPES_FOR_PICTURE_UPDATE)[number];

/**
 * Check whether a guest type is allowed to updated its picture.
 * @param guestType Guest type to check.
 * @returns Whether the given type is allowed.
 */
export function isGuestAllowedToUpdatePicture(guestType: GuestType): guestType is PictureUpdateGuestType {
  return (ALLOWED_GUEST_TYPES_FOR_PICTURE_UPDATE as string[]).includes(guestType.toLocaleLowerCase());
}

/**
 * Upload a new guest picture, replacing the previous one.
 * @param academyId User Code / Academy ID of the guest.
 * @param guestType Type of guest whose picture is changed.
 * @returns A promise tied to the file upload.
 */
export async function uploadGuestPicture(academyId: string | number, guestType: PictureUpdateGuestType) {
  return pickFileAndUpload(getDefaultPickerOptions(academyId, guestType));
}

function getStoragePath(academyId: string | number, guestType: PictureUpdateGuestType) {
  switch (guestType) {
    case 'instructor':
      return `/users/${academyId}/instructor_logos/${uuidV4()}/`;
    case 'trainee':
      return `/users/${academyId}/trainees_logos/${uuidV4()}/`;
  }
}

function getDefaultPickerOptions(academyId: string | number, guestType: PictureUpdateGuestType) {
  return {
    lang: getLocale(),
    accept: ['.jpg', '.png'],
    imageMax: [1920, 1200],
    storeTo: {
      location: 'S3',
      container: getConfig().VITE_S3_BUCKET,
      path: getStoragePath(academyId, guestType),
      region: 'eu-west-1',
    },
  } satisfies FileUploadPickOptions;
}
