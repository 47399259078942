export const NODE_ENV = {
  PRODUCTION: 'production',
};

export function getDokkuInstanceName() {
  return import.meta.env.VITE_DIGIFORMA_DOKKU_INSTANCE;
}

const devServerRoot = () => {
  const domain = import.meta.env.VITE_EXTRANET_DEV_SERVER_ROOT || 'lvh.me';
  const proto = /https/.test(window.location.href) ? 'https' : 'http';
  const port = /https/.test(window.location.href) ? 4001 : 4000;

  return `${proto}://${domain}:${port}`;
};

const dev = {
  env: 'dev',
  serverRoot: devServerRoot(window.location.href),
  FILESTACK_API_KEY: 'AJEzjj51MQ0q2d1p2ZABSz',
  VITE_S3_BUCKET: 'awfus-digiforma-dev',
  VITE_PUSHER_KEY: '3d2477e252c75a6eea8f',
  MIRO_API_KEY: '3458764570249196686',
  GET_STREAM_API_KEY: 'zwcndh2hgj52',
};

const prod = {
  env: 'prod',
  serverRoot: 'https://app.digiforma.com',
  FILESTACK_API_KEY: 'AaBTp6qRTCy2QvJFTQqXIz',
  VITE_S3_BUCKET: 'awfus-digiforma',
  VITE_PUSHER_KEY: '9a76537e643e20a5215e',
  MIRO_API_KEY: '3458764570249196686',
  GET_STREAM_API_KEY: '6nv6fdypn9pk',
};

const dokku = {
  env: 'dokku',
  serverRoot: `https://${getDokkuInstanceName()}.digiformatest.com`,
  FILESTACK_API_KEY: 'AJEzjj51MQ0q2d1p2ZABSz',
  VITE_S3_BUCKET: 'awfus-digiforma-dev',
  VITE_PUSHER_KEY: '3d2477e252c75a6eea8f',
  MIRO_API_KEY: '3458764570249196686',
  GET_STREAM_API_KEY: 'zwcndh2hgj52',
};

const staging = {
  env: 'prod',
  serverRoot: 'https://app-staging.digiforma.com',
  FILESTACK_API_KEY: 'AJEzjj51MQ0q2d1p2ZABSz',
  VITE_S3_BUCKET: 'awfus-digiforma-staging',
  VITE_PUSHER_KEY: '3d2477e252c75a6eea8f',
  MIRO_API_KEY: '3458764570249196686',
  GET_STREAM_API_KEY: 'zwcndh2hgj52',
};

const test = {
  env: 'prod',
  serverRoot: 'https://digiforma-lms-1a6e4550cf12.herokuapp.com/',
  FILESTACK_API_KEY: 'AJEzjj51MQ0q2d1p2ZABSz',
  VITE_S3_BUCKET: 'awfus-digiforma-dev',
  VITE_PUSHER_KEY: '3d2477e252c75a6eea8f',
  MIRO_API_KEY: '3458764570249196686',
  GET_STREAM_API_KEY: 'zwcndh2hgj52',
};

export function getConfig() {
  if (
    import.meta.env.VITE_EXTRANET_FORCE_DEV ||
    /lvh\.me$/.test(window.location.hostname) ||
    /localhost$/.test(window.location.hostname)
  ) {
    return dev;
  }

  if (/digiformatest\.net$/.test(window.location.hostname)) {
    return dokku;
  }

  if (/digiformastaging\.(net|com)$/.test(window.location.hostname)) {
    return staging;
  }

  if (/extranet-lms\.net$/.test(window.location.hostname)) {
    return test;
  }

  return prod;
}

export const SENTRY_DOMAIN = 'a-world-for-us';
