import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAccountInfo } from 'src/components/AccountInfoProvider';

import './AccountInfo.scss';

export function AccountInfo() {
  const { t } = useTranslation();
  const { company, firstname, lastname, logo } = useAccountInfo();
  const academyName = company || `${firstname} ${lastname}`;

  return (
    <Link to='/' className={clsx('V2_AccountInfo', { 'V2_AccountInfo--with-logo': logo?.url !== undefined })}>
      {logo?.url ? (
        <img src={logo.url} alt={t('Header.academy_logo', { name: academyName })} className='V2_AccountInfo__academy-logo' />
      ) : (
        <>{academyName}</>
      )}
    </Link>
  );
}
