import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import type { DefaultGenerics } from 'stream-chat';
import { type ChannelPreviewUIComponentProps } from 'stream-chat-react';

import { Badge } from 'src/components/mainComponents/Badge';
import { Pill } from 'src/components/mainComponents/Pill';
import { Color } from 'src/components/v2/ColorConfiguration';
import { ChannelType, getChannelType, getPrivateChannelRecipientType } from 'src/components/v2/LiveChat/liveChat.business';

import { ChannelAvatarBase } from '../ChannelAvatar';
import './CustomChannelPreview.scss';

type CustomChannelPreviewProps = {
  userId: string;
  onChannelSelected?: () => void;
  previewProps: ChannelPreviewUIComponentProps<DefaultGenerics>;
};

export function CustomChannelPreview(props: CustomChannelPreviewProps) {
  const { t } = useTranslation();
  const { channelType, channelTypeDisplayName, channelTypeColor } = getChannelTypeDisplayInfo();

  return (
    <div
      onClick={selectChannel}
      className={clsx('CustomChannelPreview', {
        'CustomChannelPreview--active': props.previewProps.active,
      })}
    >
      <div className='CustomChannelPreview__avatar'>
        <ChannelAvatarBase channelType={channelType} image={props.previewProps.displayImage} name={props.previewProps.displayTitle} />
      </div>
      <p className='CustomChannelPreview__title'>{props.previewProps.displayTitle}</p>
      <div className='CustomChannelPreview__unread-badge'>
        {props.previewProps.unread ? <Badge value={props.previewProps.unread} max={9} /> : null}
      </div>
      <Pill color={channelTypeColor}>{channelTypeDisplayName}</Pill>
    </div>
  );

  function selectChannel() {
    props.onChannelSelected?.();
    props.previewProps.setActiveChannel?.(props.previewProps.channel, props.previewProps.watchers);
  }

  function getChannelTypeDisplayInfo(): { channelType: ChannelType; channelTypeDisplayName: string; channelTypeColor: Color } {
    const channelType = getChannelType(props.previewProps.channel);
    const recipientType =
      channelType === 'private' ? getPrivateChannelRecipientType(props.previewProps.channel, props.userId) : undefined;

    if (channelType === 'private') {
      switch (recipientType) {
        case 'instructor':
          return { channelType, channelTypeDisplayName: t('LiveChat.instructor_recipient'), channelTypeColor: 'alt_2' };
        case 'trainee':
          return { channelType, channelTypeDisplayName: t('LiveChat.trainee_recipient'), channelTypeColor: 'alt_3' };
      }
    }

    return { channelType, channelTypeDisplayName: t('LiveChat.public_channel'), channelTypeColor: 'alt_1' };
  }
}
