import { ApolloProvider } from '@apollo/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { AccountInfoProvider } from 'src/components/AccountInfoProvider';
import { ConnectionLogDataProvider } from 'src/components/mainComponents/ConnectionLogDataContex/ConnectionLogDataContext';
import ThemeContextProvider from 'src/components/mainComponents/CustomColorChooser/ThemeContext';
import { ColorConfigurationLoaderProvider } from 'src/components/v2/ColorConfiguration';
import { ExtranetFeaturesProvider } from 'src/contexts/ExtranetFeaturesContext';
import { apolloClient } from 'src/store/apolloClient';
import i18n from 'src/translations/i18n';
import { AuthContextProvider } from 'src/user';

export function Providers({ children }) {
  return (
    <ApolloProvider client={apolloClient}>
      <AuthContextProvider>
        <AccountInfoProvider>
          <ExtranetFeaturesProvider>
            <BrowserRouter>
              <I18nextProvider i18n={i18n}>
                <ConnectionLogDataProvider>
                  <ThemeContextProvider>
                    <ColorConfigurationLoaderProvider>{children}</ColorConfigurationLoaderProvider>
                  </ThemeContextProvider>
                </ConnectionLogDataProvider>
              </I18nextProvider>
            </BrowserRouter>
          </ExtranetFeaturesProvider>
        </AccountInfoProvider>
      </AuthContextProvider>
    </ApolloProvider>
  );
}
