import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ActionButton, UrlButton } from 'src/components/mainComponents/buttons';

import useErrorMessage from '../hooks/useErrorMessage';
import styles from '../scss/RootErrorFallback.module.scss';
import SentryErrorBoundary from './SentryErrorBoundary';

function RootErrorFallback({ error }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const errorMessage = useErrorMessage(error.message);

  return (
    <div className={['EmptyPage', styles.errorFallback].join(' ')} role='alert'>
      <h1>{t('RootErrorFallback.title')}</h1>
      <p>{errorMessage}</p>
      <ActionButton label={t('RootErrorFallback.refresh')} clickFn={() => navigate(0)} type='secondary' />
      <UrlButton label={t('RootErrorFallback.go_home')} url='/' icon='home' type='secondary' />
    </div>
  );
}

RootErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
};

function RootErrorBoundary({ children }) {
  const location = useLocation();
  return (
    <SentryErrorBoundary resetKeys={[location]} FallbackComponent={RootErrorFallback} id='root'>
      {children}
    </SentryErrorBoundary>
  );
}

RootErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RootErrorBoundary;
