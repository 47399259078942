import { ComponentType } from 'react';

import { useAuth } from './useAuth';

type WithAuthProps = {
  auth: ReturnType<typeof useAuth>['auth'];
};

/**
 * Return a HOC that will inject auth parameters as a property to wrapped element of type Component.
 */
export function withAuth<TProps extends WithAuthProps = WithAuthProps>(Component: ComponentType<TProps>) {
  return function ComponentWithAuth(forward: Omit<TProps, keyof WithAuthProps>) {
    const { auth } = useAuth();

    // Type cast because of this TS bug:
    // https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
    return <Component {...(forward as TProps)} auth={auth} />;
  };
}
