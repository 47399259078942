import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'src/components/mainComponents/Button';
import { Dropdown, DropdownItem, DropdownLabel, DropdownSeparator } from 'src/components/mainComponents/Dropdown';
import { SvgIcon } from 'src/components/mainComponents/SvgIcon';
import { useGuestPictureUpdater } from 'src/guestFile';
import { useAuth } from 'src/user';

import './UserMenu.scss';

export function UserMenu() {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const guestPictureUpdater = useGuestPictureUpdater();
  const navigate = useNavigate();

  if (!auth?.guestInfo) {
    return null;
  }

  return (
    <Dropdown trigger={trigger()}>
      <DropdownLabel className='UserMenu__userName'>{auth.guestInfo.name}</DropdownLabel>
      <DropdownLabel className='UserMenu__accessType'>{t('Header.access', { type: `${t(auth.guestInfo.type)}` })}</DropdownLabel>
      <DropdownSeparator />
      {guestPictureUpdater.canReplace() && (
        <DropdownItem className='UserMenu__item' onClick={guestPictureUpdater.replace}>
          <SvgIcon name='upload' size='md' />
          <span className='UserMenu__item-label'>{t('Header.change_picture')}</span>
        </DropdownItem>
      )}
      {auth.switchGuestInfos?.map((switchGuest, idx) => (
        <DropdownItem
          className='UserMenu__item'
          key={idx}
          onClick={() => navigate(`/signin/passwordless_validation?token=${switchGuest.token}&redirect_path=/`)}
        >
          <SvgIcon name='user' size='md' />
          <span className='UserMenu__item-label'>{t('Header.switch_log', { type: `${t(switchGuest.type.toLowerCase())}` })}</span>
        </DropdownItem>
      ))}

      <DropdownItem className='UserMenu__item' onClick={() => navigate(`/logout`)}>
        <SvgIcon name='logout' size='md' />
        <span className='UserMenu__item-label'>{t('Header.logout')}</span>
      </DropdownItem>
    </Dropdown>
  );

  function trigger() {
    return (
      <Button size='sm' className='UserMenu__trigger'>
        {auth?.guestInfo?.logo?.url ? (
          <div className='UserMenu__picture-wrapper'>
            <img src={auth.guestInfo.logo.url} className='UserMenu__picture' />
          </div>
        ) : (
          <SvgIcon name='user' className='UserMenu__picture UserMenu__picture--generic' />
        )}
        <span className='UserMenu__fullname'>{auth?.guestInfo?.name}</span>
        <SvgIcon name='caret-down' size='md' />
      </Button>
    );
  }
}
