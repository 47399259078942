import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Button } from 'src/components/mainComponents/Button';
import { SidebarNavigation } from 'src/components/mainComponents/SidebarNavigation';
import { UserMenu } from 'src/components/mainComponents/UserMenu';
import { LiveChatInitializer } from 'src/components/v2/LiveChat/LiveChatInitializer';
import { getLocale } from 'src/translations/i18n';
import { useAuth } from 'src/user';

import { AccountInfo } from './AccountInfo';
import './HeaderBar.scss';

export function HeaderBar(props) {
  const { trainingSessionId } = props;
  const { auth } = useAuth();
  const { type, name } = auth.guestInfo;
  const locale = getLocale().toLowerCase();

  return (
    <header className='V2_HeaderBar'>
      <div className='V2_HeaderBar__container'>
        <div className='V2_HeaderBar__side'>
          {trainingSessionId && (
            <SidebarNavigation
              className={clsx('V2_HeaderBar__SidebarNavigationTrigger', {
                'V2_HeaderBar__SidebarNavigationTrigger--force-show': props.forceShowMenuTrigger,
              })}
              trainingSessionId={trainingSessionId}
            />
          )}
          <AccountInfo />
        </div>
        <div className='V2_HeaderBar__side'>
          <LiveChatInitializer />
          {type === 'trainee' && trainingSessionId && !locale.startsWith('es') && (
            <Button as='a' to='/tutorials' icon='question' color='alt_1' rounded size='2xl' />
          )}
          {trainingSessionId ? <UserMenu /> : <p>{name}</p>}
        </div>
      </div>
    </header>
  );
}

HeaderBar.propTypes = {
  forceShowMenuTrigger: PropTypes.bool,
  trainingSessionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
