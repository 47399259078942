import { clsx } from 'clsx';

import { Button } from 'src/components/mainComponents/Button';
import { Drawer } from 'src/components/mainComponents/Drawer';
import AccountInfo from 'src/components/mainComponents/HeaderBar/AccountInfo';
import { SidebarNavigationContent } from 'src/components/mainComponents/SidebarNavigationContent';
import useDisclosure from 'src/hooks/useDisclosure';

import './SidebarNavigation.scss';

export function SidebarNavigation({ className: additionalClassName, trainingSessionId = null }) {
  const className = clsx('SidebarNavigationTrigger', additionalClassName);
  const { open, close, isOpen } = useDisclosure();
  return (
    <>
      <Button icon='burger--menu' onClick={open} className={className} variant='text' size='xl' />
      <Drawer isOpen={isOpen} onClose={close}>
        <div className='SidebarNavigationTrigger__SidebarNavigation'>
          <div className='SidebarNavigationTrigger__SidebarNavigationHeader'>
            <Button icon='burger--menu' onClick={close} variant='text' size='xl' />
            <AccountInfo />
          </div>
          <div className='SidebarNavigationTrigger__SidebarNavigationContent'>
            <SidebarNavigationContent trainingSessionId={trainingSessionId} />
          </div>
        </div>
      </Drawer>
    </>
  );
}
