import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect } from 'react';

import { ConnectionLogData } from 'src/components/mainComponents/ConnectionLogDataContex/ConnectionLogDataContext';
import useIsDocumentVisible from 'src/hooks/useIsDocumentVisible';

function useConnectionLogMutation(trainingSessionId) {
  const [logConnection] = useMutation(CONNECTION_LOG, { ignoreCache: true });
  const { connectionLogState } = useContext(ConnectionLogData);
  const isTabVisible = useIsDocumentVisible();

  const doLogging = useCallback(() => {
    const { module_id, activity_id } = connectionLogState;
    if (isTabVisible) {
      logConnection({ variables: { training_session_id: trainingSessionId, module_id: module_id, activity_id: activity_id } });
    }
  }, [logConnection, trainingSessionId, connectionLogState, isTabVisible]);

  useEffect(() => {
    const interval = setInterval(() => {
      doLogging();
    }, 30000);
    return () => clearInterval(interval);
  }, [doLogging]);
}

const CONNECTION_LOG = gql`
  mutation connection_log($training_session_id: ID!, $module_id: ID, $activity_id: String) {
    connection_log(training_session_id: $training_session_id, module_id: $module_id, activity_id: $activity_id)
  }
`;

export default useConnectionLogMutation;
